/* Copyright(c) 2024 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Chaitanya
*@fileName : permittedAssets.css
@Description:5566-UI for Permitted Assets 
*/


/* .padding-consistent {
  padding-top: 7vh !important;
  padding-bottom: 20px !important;
} */

.responsive-padding {
  padding-top: 7%;
  padding-bottom: 20px;
  min-height: 100vh;
}

@media (min-width: 1024px) and (max-width: 1040px) {
  .responsive-padding {
    padding-top: 8% !important;
    padding-bottom: 20px;
    min-height: 100vh;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .responsive-padding {
    padding-top: 9% !important;
    padding-bottom: 20px;
    min-height: 100vh;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .responsive-padding {
    padding-top: 11% !important;
    padding-bottom: 20px;
    min-height: 100vh;
  }
}

@media (min-width: 425px) and (max-width: 480px) {
  .responsive-padding {
    padding-top: 18% !important;
    padding-bottom: 20px;
    min-height: 100vh;
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  .responsive-padding {
    padding-top: 20% !important;
    padding-bottom: 20px;
    min-height: 100vh;
  }
}

@media (max-width: 375px) {
  .responsive-padding {
    padding-top: 25% !important; /* Ensure mobile screens match */
  }
}

/* Chaitanya-5566-changed button colors */
.swal-custom-btn {
  background-color: #0A61AD !important; /* Custom color (e.g., red-orange) */
  color: white !important; /* Text color */
  border: none !important;
  box-shadow: none !important;
}